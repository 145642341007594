@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family:'Satoshi', sans-serif !important;
  src: url(./assets/font/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf);
  font-weight: 500 !important;
}

html{
  scroll-behavior: smooth !important;
}

body {
  margin: 0;
  font-family:'Satoshi', sans-serif !important;
  padding: 0px;
  scroll-behavior: smooth !important;
}
.Satoshi{
  font-family:'Satoshi', sans-serif !important;
}

.logotext{
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 800;
}

.hrcolor{
  background: black !important;
}

.containers {
  width: 95% !important;
  margin: 0 auto;
 
}
.filter{
  filter: grayscale(100%);
}
.filter:hover{
  filter: grayscale(0%);
}
.herpgr{
  background: linear-gradient(135deg, #55569f, #2d5dc5d3, #2f3077, #844994, #b3827f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btnprpgr{
  background: linear-gradient(135deg, #2f3077, #844994, #b3827f);

}
.btnprpgr:hover{
  background: linear-gradient(311deg, #2f3077, #844994, #b3827f) !important;

}
/* input[type=checkbox]{
  background-color: #a77e2d !important;
  color: #ffffff !important;
}
input[type='checkbox']{
    box-shadow: none !important;
} */
.cursor-zoom-in {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><text x="50%" y="50%" font-size="24" text-anchor="middle">+</text></svg>'), 28;
}

.cursor-zoom-out {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><text x="50%" y="50%" font-size="24" text-anchor="middle">-</text></svg>'), 28;
}
.thankshadow{
  box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.64);

}
.mobilevbg{
background: rgba(255, 255, 255, 0.07);
box-shadow: 0px 2.961px 55.371px 0px rgba(0, 0, 0, 0.14);
backdrop-filter: blur(12.547403335571289px);
}
/* .checkBox{
  accent-color: white !important;
} */
input[type="checkbox"] {
  display: none;
}
/* Create a custom checkbox */
.custom-checkbox {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: #FFFFFF;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}
/* Style the checkmark/indicator */
.custom-checkbox::after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.shadowbox{
  box-shadow: 0px 2.961px 55.371px 0px rgba(144, 125, 165, 0.44);
}

input[type="checkbox"]:checked + .custom-checkbox::after {
  display: block;
}
.btnprpgr:hover{
  background:#fff;

}
.shadowbox {
  box-shadow: 0px 2.96px 55.37px 0px rgba(144, 125, 165, 0.44);
}
.linkbtngr{
  background-image: url(../src/assets/images/linkegr.png);
  background-size: cover;
  background-repeat: no-repeat;

}
.bgpric{
  background-image: url(../src/assets/images/bgimgcolor.png);
  background-position: left;
}


  .cardswrapper :nth-child(15){
    margin-top: -40rem !important;
    }
 @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s linear infinite;
}
 
  .hrclas{
    background-color: #000 !important;
    height: 3px !important;
  }
 

    .tf-v1-widget-fullscreen{
      position: unset !important;
    }
    .tf-v1-widget{
      min-height: 100vh !important;
    }
  @media only screen and (max-width: 600px) {
    .tf-v1-widget{
      min-height: 92vh !important;
    }
    .cardswrapper :nth-child(4) {
      margin-top: 0rem !important;
    }
   
  }
   

.hero-bg-img {
  background-image: url(./assets/images/64eb7153d04155015b84a72d_Group\ 6858arrows.svg);
  background-size: cover;
}
.bg-needastart-img{
  background-image: url(./assets//images/64eb6c3c811b07c6ca1961ae_DS_SHAPE.svg);
  background-color: #dbc4f0;
  height: 90vh;
  background-position: center;
  background-size: cover;
  border-radius: 24px;
}
.howit-img{
  background-image: url(./assets/images/mainbg.png);
  background-repeat: repeat;
  background-color: #f0f0f0;
}
.gallery-wrap{
  padding: 100px 0px;
  flex-direction: column;
    display: flex;
    overflow: hidden;
}
.fstbox{
  transform: translateX(-10%);
}
.sndbox{
  transform: translateX(-1%);
}
.gallery{

    will-change: transform;
    transform: translate3d(-11.4086vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}


.product-carousel {
  padding: 0px 0px;
  align-items: end;
}

.product-container {
  padding: 0 0px;
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.pre-btn,
.next-btn {
  border: none;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
}

.next-btn {
  right: 0;
}

.next-btn p,
.pre-btn p {
  font-size: 50px;
  background-color: rgba(255, 255, 255, 0.436);
  box-shadow: 0px 0px 10px 1px black;
  border-radius: 10px;
  color: rgb(0, 0, 0);
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.mycard {
  padding-right: 10px;
  padding-top: 10px;
  color: white;
} 
.swiper-slide .swiper-slide-active{
  width: 100% !important;
}


/*  */
.tf-v1-widget-fullscreen{
  background-color: #ffffff00 !important;
}
.renderer-in{
  height: 96% !important;
}
.bILONE{
height: 96% !important;
}

#logoMarqueeSection {
  max-width: 1920px !important;
  margin: 0 auto;
  overflow: hidden;
}

.default-content-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  min-height: 100vh;
}
.default-content-container1 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

div.marquee>a>img {
  height: 60px;

}

.logoMarqueeSection>div>div {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 0;
}

.marquee-wrapper {
  display: inline-block;
  white-space: nowrap;
}
.marquee-wrapper1 {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
}

.marquee {
  animation-duration: 57s;
  display: inline-block;
  white-space: nowrap;
  position: relative;
  transform: translate3d(0%, 0, 0);
  animation-name: marquee;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.marquee a {
  display: inline-block;
  white-space: nowrap;
  padding-right: 5.4rem;
}

@keyframes marquee {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.link a{
  color: #d7b98e !important;
}
.hbtngr{
  background: linear-gradient(90deg, #5156B4 0%, #8F4C7D 100%) !important;
}
.hbtngr2{
  background: linear-gradient(90deg, #000000 0%, #595959 100%)
}


.box {
  position: relative;
}
.box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px; 
  padding: 2px; 
  background:linear-gradient(45deg,#5156B4,#8F4C7D); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}
.bghero {
  background-color: rgba(244, 117, 54, 0.13);
}
.buttonhove {
  transition: opacity 0.3s ease, padding-top 0.3s ease;
}
textarea::placeholder {
  color: rgb(63, 63, 63);
}
.main-banner .playbtn {
  border-radius: 56px;
  width: 60px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 0 rgba(128, 63, 63, .027);
  animation: buttonanim 2s infinite;
  padding: 0 !important;
}
@keyframes buttonanim {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 0 0 rgba(255, 146, 82, .7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px transparent;
  }
}
/* .cardContainer{
  overflow: hidden;
  cursor: pointer;
}
.animteCard{
  transition: all ease-in-out .5s;
  transform: translateY(140px);
}
.cardContainer:hover .animteCard{
  transform: translateY(0);
} */
.link a{
  color: #8f78f0;
}
.react-tel-input .form-control{
  width: 100% !important;
  height: 45px !important;
  border-radius: 30px !important;
  background-color: rgb(255 255 255 / 0.5)!important;
  --tw-backdrop-blur: blur(24px) !important;
backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  color: #fff !important;
}
.filed input:nth-of-type(1){
  background-color: rgba(255, 255, 255, 0)!important;
  --tw-backdrop-blur: blur(24px) !important;
backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  color: #fff !important;
} 
.react-tel-input .form-control:checked{
  background-color: rgba(255, 255, 255, 0)!important;
  --tw-backdrop-blur: blur(24px) !important;
backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  color: #fff !important;
}

.react-tel-input .flag-dropdown{
background:  transparent !important;
border-radius: 30px !;
border: none !important;
left: 1% !important;

}
.react-tel-input .selected-flag{
  background-color: rgba(255, 255, 255, 0)!important;
  padding-left: 10px !important;
  width: 45px !important;
border-radius: 30px 0px 0px 30px !important;
}
.play {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.react-tel-input .country-list{
  background-color: rgb(255 255 255 / 0.5)!important;
  --tw-backdrop-blur: blur(24px) !important;
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}
input::placeholder{
  color: rgb(41, 41, 41) !important;
}
.card-text {
  position: relative;
}
.check-box{
  accent-color: rgb(255, 255, 255) !important;
}
/* Styles for the card container */
.card-container {
  /* ... other styles remain unchanged ... */
  position: relative;
  overflow: hidden; /* Hide overflowing content */
}

.title {
  position: relative;
  border-bottom: 2px solid transparent; 
  transition: border-color 0.3s ease-in-out;
  cursor: pointer; 
  padding-bottom: 2px; 
}

.title::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: black; 
  transition: width 0.3s ease-in-out; 
}
.card-container:hover .title::before {
  width: 100%; 
}

.bgcardprcing{
  background-image:url(../src/assets/images/bgimgcolor.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 20px 20px 0px 0px;

}
.bgcardprcingform{
  background-image:url(../src/assets/images/bgimgcolor.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  height: 100vh;


}
.bggrcheck{
  background-image:url(../src/assets/images/bgimgcolor.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  min-height: 100vh;
}
.jToXXE {
  display: inline-block;
  z-index: -20;
  line-height: 0;
  opacity: 1;
  background-color: rgb(47, 89, 126);
  background-image: none !important;
  background-position: center top;
  background-size: cover;
  height: 100% !important;
  width: 0% !important;
  min-height: 100vh !important;
  position: absolute;
  inset-block-start: 0px;
  inset-inline-start: 0px;
}
.transianim{
transition:ease-in-out 0.3s;
}
.hovcolor {
 color: #fff ;
}

.card-container:hover .play {
  opacity: 1;
}
.backgroungmain{
  background-image: linear-gradient(to bottom left, #ffffff 28%, #e2e2e2 45%)
}
.backgroungmain-overpay{
  background-image: linear-gradient(to bottom left, #ffffff 11%, #e2e2e2 62%);
}
.backgroungmain-home{
  background-image: linear-gradient(to bottom left, #e2e2e2 35%, #ffffff 58% )
}
.backgroungmain-nav{
  background-image: linear-gradient(to bottom left, #ffffffe8 60%, #e2e2e2c9 89%);
}
.overpay .drop{
  border: 1px solid gray !important;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
  color: #6698c7 !important;
}
.hoverbg:hover{
  background-color: #fcfcfcea !important;
}
/* Default Swiper styles */
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, 
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  background: #6698c7 !important;
}

.swiper-button-next, 
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
  color: #6698c7 !important;
}



.cardContainer{
  overflow: hidden;
  cursor: pointer;
  transition: all ease-in-out .5s;

}

.cardContainer:hover{
  padding: 0px 20px;
  transition: ease-in-out .5s;

}
.cardParentzoom {
  transition: transform 0.4s ease-in-out;
}

.cardParentzoom:hover .animzoom {
  transform: scale(1.1) rotate(360deg); /* Adjust scale and rotation as needed */
  transition: transform 0.4s ease-in-out;
}

.cardContainer:hover .animteCard{
  transform: rotate(360deg);
  transition: all ease-in .5s;

}

.animteCard i{
  color: #ff70d3;

}
.cardContainer:hover i{
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1);
}
@keyframes ping {
  75%, 100% {
    transform: scale(1.7);
  }
}
.animteCard p{
  transition: all ease-in-out .5s;
  opacity: 0;
  height: 160px;
}
.cardContainer:hover p{
  opacity: 1;
  /* display: block; */
}
.btn1{
  background-color: #D7B98E;
}

.animate-slide {
  animation: slide 130s linear infinite; /* Update animation duration */
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%); /* Translate by half of the container width */
  }
}
.animate-slide2 {
  animation: slide2 120s linear infinite;
}
@keyframes slide2 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%); 
  }
}
.btn2{
  background-color: #000080;
}
.animate-slide3 {
  animation: slide3 110s linear infinite;
}
@keyframes slide3 {
   0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%); 
  }
}
.btn3{
  background-color: #205a90;
}
.servicecls{
overflow: hidden;
}
@media only screen and (max-width: 600px) {
  .mycard {
    padding: 0px !important;
    color: white;
  } 
  .bggrcheck{
    background-image:url(../src/assets/images/bgmobcheck.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    min-height: 100vh;
  }
}



/*  */
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.fancybox__backdrop{
  background-color: #00000091 !important;
}
/* .fancybox__footer{
  display: none !important;
} */
.swiper-slide {
  a {
    display: block;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    &:hover .image .overlay {
      opacity: 1;
    }
  }
}
/* .is-compact .fancybox__footer{
  display: none !important;
} */
.image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
.bgtopbar{
  background-image: url(../src/assets/images/topbar.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(204, 204, 204, 0.8);
  text-align: center;
  opacity: 0;
  transition: all 0.2s linear;

  em {
    color: #fff;
    font-size: 26px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
  }
}

.swiper-pagination {
  position: relative;
  bottom: auto;
  text-align: center;
  margin-top: 25px;
}

.swiper-pagination-bullet {
  transition: all 0.2s linear;

  &:hover {
    opacity: 0.7;
  }

  &.swiper-pagination-bullet-active {
    background-color: #d63031;
    transform: scale(1.1, 1.1);
  }
}

/* ///// */
